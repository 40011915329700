import { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import bluredAccidentsRisks from '../../../images/bluredRisks/bluredAccidentsRisks.png';
import bluredHealthRisks from '../../../images/bluredRisks/bluredHealthRisks.png';
import {
	ApiReportType,
	CountryCircumstanceDestinationType,
	DistributionsType,
} from '../../api/types/apiGetReport';
import Alert from '../../components/Alert/Alert';
import { useContextNavigation } from '../../components/ContextNavigation';
import useMediaQuery from '../../hooks/MediaQuery';
import { isEmptyObj } from '../../lib';
import { DEVICES } from '../../lib/breakpoints';
import cn from '../../lib/classNames';
import { ViolentCrimeRisk } from '../Dashboard';
import Layout from '../Layout/LayoutReport';
import { PAGES, PAGES_NAVIGATION, ReportsType } from '../pagesNavigation';

import Banner from './components/Banner';
import CitiesViCRIBlock from './components/CitiesViCRIBlock/CitiesViCRIBlock';
import NationalViCRIBlock from './components/NationalViCRIBlock/NationalViCRIBlock';
import TravelAdvice from './components/TravelAdvice';
// TODO: need for test
// import mockDataAI from './mockData/mockDataAI.json';
import ReportNavigationMobile from './ReportNavigatin/ReportNavigationMobile';
import AiPlaceholder from './RiskSafetySummary/AiPlaceholder/AiPlaceholder';
import Loader from './RiskSafetySummary/Loader';
import RiskSafetySummary from './RiskSafetySummary/RiskSafetySummary';
import RiskSafetySummaryDemo from './RiskSafetySummaryDemo/RiskSafetySummaryDemo';
import {
	AdditionalFeaturesContainer,
	Footer,
	KeyConcept,
	KeyPersonalRiskDrivers,
	RiskHighlightsIndicatorContainer,
	RiskScoresEstimates,
	RiskScoresEstimatesBlured,
	TravelHealthNotices,
} from './components';

import styles from './TravelRiskReport.module.scss';

type TravelRiskReportType = {
	className?: string;
	reportVariant?: string;
	reportData: ApiReportType;
	renderHeaderFooter?: boolean;
};

type ScrollPositionType = {
	[key: string]: number;
};

// TODO Test data (do not delete).

// const cdcNoticesTestData = {
// 	LEVEL_3: [
// 		{
// 			title: 'LEVEL_3',
// 			description: 'description LEVEL_3',
// 			sourceUrl: 'https://test.com',
// 			datePublished: '20231002',
// 		},
// 	],

// 	LEVEL_1: [
// 		{
// 			title: 'LEVEL_1',
// 			description: 'description LEVEL_1',
// 			sourceUrl: 'https://test.com',
// 			datePublished: '20231002',
// 		},
// 	],

// 	LEVEL_2: [
// 		{
// 			title: 'LEVEL_2',
// 			description: 'description LEVEL_2',
// 			sourceUrl: 'https://test.com',
// 			datePublished: '20231002',
// 		},
// 	],
// };

export default function TravelRiskReport(props: TravelRiskReportType) {
	// TODO: need for test
	const { className, reportVariant, reportData, renderHeaderFooter = false } = props;

	const buyReportLink = reportData?.buyReportLink;

	const [isLimitedVersion, setIsLimitedVersion] = useState(!!buyReportLink);
	const [isMobileMenu, setIsMobileMenu] = useState(true);

	const [scrollPositions, setScrollPositions] = useState<ScrollPositionType>({});
	const [isToggleReport, setIsToggleReport] = useState(false);

	const prevScrollPos = useRef(window.scrollY);

	const { setIsReportMenuVisible, setPageNavigation, activePage, setActivePage } =
		useContextNavigation();

	const isMobile = useMediaQuery(DEVICES.isMobile);

	const reportRef = useRef<HTMLDivElement | null>(null);

	const reportDataHomeCovid = reportData?.covid?.home;
	const reportDataDestinationCovid = reportData?.covid?.destination;
	const reportDataFromToDetails = reportData?.fromToDetails;
	const reportDataCrimeIndex = reportData.crimeIndex;
	const reportDatahomeDestinationScoreDetails = reportData?.homeDestinationScoreDetails;
	const designatedAreaContent = reportData?.destinationCompanyCircumstances;

	const distributionsCountry: DistributionsType = reportData.crimeBarCharts
		? {
				HR: reportData.crimeBarCharts.country.HR,
				VCR: reportData.crimeBarCharts.country.VCR,
				Index: reportData.crimeBarCharts.country.Index,
		  }
		: {
				HR: reportData.crimeHRBarChart,
				VCR: reportData.crimeVCRBarChart,
				Index: reportData.crimeIndexBarChart,
		  };

	const cityLevelDataExist = reportData?.crimeBarCharts;

	const distributionsPlace: DistributionsType = {
		HR: reportData?.crimeBarCharts?.place?.HR,
		VCR: reportData?.crimeBarCharts?.place?.VCR,
		Index: reportData?.crimeBarCharts?.place?.Index,
	};

	const aiReport = reportData?.aiReport;
	// TODO: need for test
	// const aiReport = mockDataAI;

	const aiReportDemo = reportData?.aiReportDemo;
	const destinationCoordinates = {
		lat: reportData?.destinationBoundaryMetaLat || reportData?.covid.destination.covidLat,
		lng: reportData?.destinationBoundaryMetaLng || reportData?.covid.destination.covidLng,
	};

	const cdcNotices = reportData?.cdcNotices;
	// TODO Test data (do not delete).
	// const cdcNotices = cdcNoticesTestData;
	const isCdcNotices =
		cdcNotices &&
		Object.values(cdcNotices).some((level) => Array.isArray(level) && level.length > 0);

	const isTravelSupportInfo = reportData?.travelSupportInfo?.code2;

	const isConflictDestination = reportData?.destinationCircumstances.includes('MILITARY_CONFLICT');
	// const isConflictDestination = (
	// 	reportData?.destinationCircumstances as CountryCircumstanceDestinationType[]
	// ).includes('MILITARY_CONFLICT');
	const isDesignatedArea = !!designatedAreaContent.length;

	const setDefaultScrollPosition = () => {
		PAGES_NAVIGATION.forEach((item) => {
			setScrollPositions((prev) => ({
				...prev,
				[item.id]: 0,
			}));
		});
	};

	const handleScroll = useCallback(() => {
		const currentScrollPos = window.scrollY;
		const shouldShowMobileMenu = prevScrollPos.current > currentScrollPos;

		setIsToggleReport((oldState) => {
			setIsMobileMenu(currentScrollPos <= 100 || oldState || shouldShowMobileMenu);
			return shouldShowMobileMenu;
		});

		prevScrollPos.current = currentScrollPos;

		setScrollPositions((prev) => ({
			...prev,
			[activePage.id]: prevScrollPos.current,
		}));
	}, [prevScrollPos, activePage.id]);

	useEffect(() => {
		setIsReportMenuVisible(true);
		setActivePage(PAGES.riskScoring);

		setDefaultScrollPosition();

		window.scrollTo({
			top: 0,
		});

		return () => {
			setIsReportMenuVisible(false);
		};
	}, []);

	useEffect(() => {
		setPageNavigation((prevData: ReportsType[]) => {
			const newData = prevData.map((item) => {
				if (item.id === PAGES.riskBrief.id) {
					return {
						...item,
						btnText: isLimitedVersion
							? `${PAGES.riskBrief.btnText} (DEMO)`
							: PAGES.riskBrief.btnText,
					};
				}
				return item;
			});
			return newData;
		});
	}, [isLimitedVersion]);

	useEffect(() => {
		if (isMobile) {
			window.addEventListener('scroll', handleScroll);
		}

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll, isMobile]);

	useEffect(() => {
		setIsToggleReport(true);
		const startScrollPosition = scrollPositions[activePage.id];

		window.scrollTo({
			top: startScrollPosition,
		});

		setTimeout(() => {
			setIsMobileMenu(true);
		}, 500);
	}, [activePage.id]);

	const isAiPlaceholder = !aiReport && aiReport !== null && !aiReportDemo;

	const aiPlaceholder = (
		<AiPlaceholder
			title="AI-Powered Risk Intelligence Brief"
			description="A comprehensive analysis and critical information resource for your destination. Includes:"
		/>
	);
	const renderAiReport = () => {
		if (activePage.id === PAGES.riskBrief.id) {
			if (aiReport) {
				return (
					<RiskSafetySummary
						data={aiReport}
						showWarningMessage={isConflictDestination || isDesignatedArea}
						destination={reportDataFromToDetails?.destination}
					/>
				);
			}
			if (aiReportDemo && !isEmptyObj(aiReportDemo)) {
				return <RiskSafetySummaryDemo dataDemo={aiReportDemo} buyReportLink={buyReportLink} />;
			}
			if (aiReport === null) {
				return <Loader />;
			}
			if (isAiPlaceholder) {
				return aiPlaceholder;
			}

			return aiPlaceholder;
		}

		return null;
	};

	const renderReports = () => {
		return (
			<>
				<CSSTransition
					in={activePage.id === PAGES.riskScoring.id}
					appear
					timeout={100}
					classNames={{
						enterDone: styles['report-wrap-done-enter'],
					}}
					unmountOnExit
				>
					<section className={cn(styles.section, styles['report-wrap'])}>
						{isConflictDestination && (
							<section className={styles.section}>
								<Alert
									type="warning"
									icon="warning_amber"
									title="Attention:"
									text="Conflict deaths and injuries are not categorized and/or included in the Safe-esteem Violent Crimes scores and estimates (which owe their accuracy to the very specificity of the risk being measured). Active conflict areas are likely to carry considerably higher (and additional) risks of violence beyond homicide, aggravated assault, robbery, rape (represented below)."
								/>
							</section>
						)}

						{!!designatedAreaContent?.length && (
							<section className={styles.section}>
								<Banner content={designatedAreaContent[0]} iconName="warning_amber" />
							</section>
						)}

						<section className={styles.section}>
							<h3>Violent Crime Risk Index Classes</h3>
							<p className="sub-title">
								Ranking according to the Violent Crime Risk (ViCRI) Index for cities and countries
							</p>
						</section>

						{!cityLevelDataExist && (
							// {cityLevelDataExist && (
							<section
								className={cn(
									styles.section,
									styles['violent-crime-risk-container'],
									'section-wrap-white',
								)}
							>
								<ViolentCrimeRisk
									title="The Violent Crime Risk Index for Global Cities"
									subTitle="Your home and destination ranking of homicide, robbery, rape, and aggravated assault risks."
									showTooltipIcon
									showDownloadScreenIcon={false}
									locationHome={
										reportDataCrimeIndex?.home?.location || reportDataFromToDetails?.home
									}
									locationDestination={
										reportDataCrimeIndex?.destination?.location ||
										reportDataFromToDetails?.destination
									}
									riskValueHome={reportDataCrimeIndex.home.index}
									riskValueDestination={reportDataCrimeIndex.destination.index}
									homeCrimeRiskIndexLocations={reportDataCrimeIndex?.home?.locations}
									destinationCrimeRiskIndexLocations={reportDataCrimeIndex?.destination?.locations}
									locationCircumstancesHome={reportData?.homeCircumstances}
									locationCircumstancesDestination={reportData?.destinationCircumstances}
									isShowEstimate={false}
								/>
							</section>
						)}

						<section className={styles.section}>
							<NationalViCRIBlock
								dataLayer={-1}
								showDownloadScreenIcon={false}
								countries={reportData?.nationalCrimeIndexGeography}
								crimeIndexGeography={reportData?.crimeIndexGeography}
								home={reportDataHomeCovid}
								destination={reportDataDestinationCovid}
								destinationCoordinates={destinationCoordinates}
								distributions={distributionsCountry}
								isLimitedVersion={isLimitedVersion}
								homeCode={reportDataFromToDetails?.homeCountryCode}
								destinationCode={reportDataFromToDetails?.destinationCountryCode}
							/>
						</section>
						{cityLevelDataExist && (
							<section className={styles.section}>
								<CitiesViCRIBlock
									showDownloadScreenIcon={false}
									// crimeIndexGeography={reportData?.crimeIndexGeography}
									// home={reportDataHomeCovid}
									// destination={reportDataDestinationCovid}
									distributions={distributionsPlace}
									isLimitedVersion={isLimitedVersion}
									homeCode={reportDataFromToDetails?.homeCountryCode}
									destinationCode={reportDataFromToDetails?.destinationCountryCode}
									locationHome={
										reportDataCrimeIndex?.home?.location || reportDataFromToDetails?.home
									}
									locationDestination={
										reportDataCrimeIndex?.destination?.location ||
										reportDataFromToDetails?.destination
									}
									riskValueHome={reportDataCrimeIndex.home.index}
									riskValueDestination={reportDataCrimeIndex.destination.index}
									homeCrimeRiskIndexLocations={reportDataCrimeIndex?.home?.locations}
									destinationCrimeRiskIndexLocations={reportDataCrimeIndex?.destination?.locations}
									locationCircumstancesHome={reportData?.homeCircumstances}
									locationCircumstancesDestination={reportData?.destinationCircumstances}
								/>
							</section>
						)}

						<section className={styles.section}>
							<h3>Personalized Risk Scores Estimates</h3>
							<p className="sub-title">Underlying risk rates compared to home region</p>
						</section>

						<section className={cn(styles.section, styles['risk-scores-estimates-container'])}>
							<RiskScoresEstimates
								data={reportDatahomeDestinationScoreDetails?.scores?.crime}
								risk="crime"
							/>

							{isLimitedVersion ? (
								<RiskScoresEstimatesBlured
									title="Accidents Risks"
									description="Personalized injury risk estimates are available."
									buttonText="UNLOCK PRO REPORT"
									bluredImage={bluredAccidentsRisks}
									link={buyReportLink}
									destination={reportDataFromToDetails?.destination}
								/>
							) : (
								<RiskScoresEstimates
									data={reportDatahomeDestinationScoreDetails?.scores?.accidents}
									risk="accidents"
								/>
							)}

							{isLimitedVersion ? (
								<RiskScoresEstimatesBlured
									title="Health Risks"
									description="Personalized communicable and non-communicable health risk estimates are available."
									buttonText="UNLOCK PRO REPORT"
									bluredImage={bluredHealthRisks}
									link={buyReportLink}
									destination={reportDataFromToDetails?.destination}
								/>
							) : (
								<RiskScoresEstimates
									data={reportDatahomeDestinationScoreDetails?.scores?.health}
									risk="health"
									fullView
								/>
							)}
						</section>
						<section className={styles.section}>
							<h3>Relative Risk Weighting</h3>
							<p className="sub-title">By risk domain</p>
						</section>

						<section className={cn(styles.section, styles['relative-risk-weighting-container'])}>
							<div className={styles['row-1']}>
								<div className={styles.col}>
									<KeyPersonalRiskDrivers
										label="At residence city"
										typeIcon="home_filled"
										location={reportDataFromToDetails?.home}
										ratio={reportDatahomeDestinationScoreDetails?.ratio?.home}
									/>
								</div>
								<div className={styles.col}>
									<KeyPersonalRiskDrivers
										label="At destination city"
										typeIcon="location_on"
										location={reportDataFromToDetails?.destination}
										ratio={reportDatahomeDestinationScoreDetails?.ratio?.destination}
									/>
								</div>
							</div>
						</section>

						<section className={styles.section}>
							<h3>Additional Risk Highlights, Indicators, and Advice</h3>
							<p className="sub-title">Ratings are national except where clearly indicated</p>
						</section>

						<section className={styles.section}>
							<RiskHighlightsIndicatorContainer
								isLimitedVersion={isLimitedVersion}
								data={reportData}
								buyReportLink={buyReportLink}
							/>
						</section>

						{isCdcNotices && (
							<>
								<section className={styles.section}>
									<h3>Travel Health Notices</h3>
									<p className="sub-title">Department of State Safety and Security Alerts</p>
								</section>

								<section className={styles.section}>
									<TravelHealthNotices cdcNotices={cdcNotices} />
								</section>
							</>
						)}

						{!!isTravelSupportInfo && (
							<section className={styles.section}>
								<TravelAdvice travelSupportInfo={reportData.travelSupportInfo} />
							</section>
						)}

						{/* TODO: need to remove after API data refactor. Start */}
						{/* <section className={styles.section}>
							<h3>COVID-19</h3>
							<p className="sub-title">Special destination travel features</p>
						</section>
						<section className={cn(styles.section, styles['geographic-risk-rating'])}>
							<div className={styles.row}>
								<div className={styles.col}>
									<GeographicRisk
										showDownloadScreenIcon={false}
										headerText="COVID-19 Geographic Risk Rating"
										headerLabelLocation="Residence:"
										dataLayer={reportDataHomeCovid?.covidMaxLevel}
										riskValue={reportDataHomeCovid?.covidBucket}
										riskLocation={reportDataHomeCovid?.covidRankingLocation}
										riskLabel={reportDataHomeCovid?.covidRankingGroup}
										location={{
											value: {
												longitude: reportDataHomeCovid?.covidLng || 0,
												latitude: reportDataHomeCovid?.covidLat || 0,
											},
										}}
										colors="blue"
									/>
								</div>
								<div className={styles.col}>
									<GeographicRisk
										showDownloadScreenIcon={false}
										headerText="COVID-19 Geographic Risk Rating"
										headerLabelLocation="Desination:"
										dataLayer={reportDataDestinationCovid?.covidMaxLevel}
										riskValue={reportDataDestinationCovid?.covidBucket}
										riskLocation={reportDataDestinationCovid?.covidRankingLocation}
										riskLabel={reportDataDestinationCovid?.covidRankingGroup}
										location={{
											value: {
												longitude: reportDataDestinationCovid?.covidLng || 0,
												latitude: reportDataDestinationCovid?.covidLat || 0,
											},
										}}
										colors="blue"
									/>
								</div>
							</div>
						</section>
						<section className={cn(styles.section, styles['geographic-risk-trands'])}>
							<div className={styles.row}>
								<div className={styles.col}>
									<GeographicRiskTrends
										showDownloadScreenIcon={false}
										headerLabelLocation="Residence"
										dataLayer={reportDataHomeCovid?.covidTrendMaxLevel}
										casesTrend={reportDataHomeCovid?.covidCasesTrend}
										deathTrend={reportDataHomeCovid?.covidDeathTrend}
										covidTrendHistory={reportDataHomeCovid?.covidTrendHistory}
										location={reportDataHomeCovid?.covidRankingLocation}
									/>
								</div>
								<div className={styles.col}>
									<GeographicRiskTrends
										showDownloadScreenIcon={false}
										headerLabelLocation="Desination"
										dataLayer={reportDataDestinationCovid?.covidTrendMaxLevel}
										casesTrend={reportDataDestinationCovid?.covidCasesTrend}
										deathTrend={reportDataDestinationCovid?.covidDeathTrend}
										covidTrendHistory={reportDataDestinationCovid?.covidTrendHistory}
										location={reportDataDestinationCovid?.covidRankingLocation}
									/>
								</div>
							</div>
						</section>
						<section className={cn(styles.section, styles['statistics-map'])}>
							<div className={styles.row}>
								<div className={styles['col-1']}>
									<StatisticsMap
										showDownloadScreenIcon={false}
										headerText={
											<>
												COVID Top 10
												<p>Countries with Highest COVID19 Risks</p>
											</>
										}
										dataLayer={-1}
										countries={reportData?.covid?.covidGeography}
										home={{
											countryCode: reportDataHomeCovid?.countryCode,
											covidBucket: reportDataHomeCovid?.countryCovidBucket,
										}}
										destination={{
											countryCode: reportDataDestinationCovid?.countryCode,
											covidBucket: reportDataDestinationCovid?.countryCovidBucket,
										}}
									/>
								</div>
								<div className={styles['col-2']}>
									<CountriesStatistics
										showDownloadScreenIcon={false}
										data={reportData?.covid?.topDeathCovidGeography}
									/>
								</div>
							</div>
						</section> */}
						{/* TODO: need to remove after API data refactor. Finish */}

						{isLimitedVersion && (
							<section className={styles.section}>
								<AdditionalFeaturesContainer
									buyReportLink={buyReportLink}
									reportVariant={reportVariant}
									destination={reportDataFromToDetails?.destination}
								/>
							</section>
						)}

						<section className={styles.section}>
							<h3>Safe-xplore Key Concepts and Terminology</h3>
						</section>

						<section className={styles.section}>
							<KeyConcept />
						</section>

						{renderHeaderFooter && (
							<section className={styles.section}>
								<Footer />
							</section>
						)}
					</section>
				</CSSTransition>

				<CSSTransition
					in={activePage.id === PAGES.riskBrief.id}
					appear
					timeout={100}
					classNames={{
						enterDone: styles['report-wrap-done-enter'],
					}}
					unmountOnExit
				>
					<section className={cn(styles.section, styles['report-wrap'])}>
						{renderAiReport()}
					</section>
				</CSSTransition>
			</>
		);
	};

	const render = () => {
		return (
			<div ref={reportRef} id="travel-risk-report" className={styles['travel-risk-report']}>
				<div className={cn(styles.content, 'content')}>
					{renderReports()}

					{isMobile && (
						<div className={cn(styles['nav-mobile-container'], { [styles.active]: isMobileMenu })}>
							<ReportNavigationMobile />
						</div>
					)}
				</div>
			</div>
		);
	};

	return <Layout className={className}>{render()}</Layout>;
}
